import React from "react"

import { Helmet } from "react-helmet"

import { Container, Row, Col } from "react-bootstrap"
import LayoutEn from "../components/layoutEn"

const InfoEn = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "en-section info-section" }} />
      <LayoutEn>
        <Container className="desc-contain">
          <Row>
            <Col>
              <p className="intro-address">
                Calle Ventosa 28, 28005, Madrid Spain
              </p>
              <p className="desc">
                <span className="desc-subhead">GENERAL:</span>
                <ul>
                  <li>
                    Exterior apartment completely renovated and ready to move
                    into in the neighborhood of Palacio.
                  </li>
                  <li>
                    Furnished, fully equipped kitchen, air conditioning, 2
                    bedrooms, one bathroom (57 m2).
                  </li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">CHARACTERISTICS:</span>
                <ul>
                  <li>62m2 apartment totally refurbished</li>
                  <li>Exterior in building with elevator</li>
                  <li>2 Bedrooms</li>
                  <li>1 Bathrooms</li>
                  <li>AACC cold / heat in all rooms.</li>
                  <li>Fully equipped kitchen.</li>
                  <li>Furnished and equipped to detail</li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">RENTAL CONDITIONS:</span>
                <ul>
                  <li>1 month deposit</li>
                  <li>Proof of economic solvency (contract, paychecks.)</li>
                  <li>
                    Expenses for supplies in charge of the tenant. IBI and
                    community expenses are to be paid by the owner.
                  </li>
                  <li>Monthly payment: 1190 Euros</li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">DESCRIPTION:</span>
                <br />
                Housing rehabilitated in 2019 in an integral way by the study of
                architecture and construction GMT+ Architecture &amp; Design. It
                has made the most of the space to turn it into a comfortable and
                cozy apartment. It has a master bedroom with fitted closets. The
                kitchen is equipped with all appliances. The living room and the
                second bedroom, separated by a glass structure to give
                spaciousness to the rooms, have two large windows overlooking
                the Ventosa street. In fact, all the rooms have windows, which
                provides plenty of light.
              </p>
              <p className="desc">
                <span className="desc-subhead">
                  THE BUILDING AND THE NEIGHBORHOOD:
                </span>
                <br />A well-kept building from 1921 located in Ventosa Street,
                between Gran Vía de San Francisco and Ronda de Segovia, a few
                meters from Puerta de Toledo. It belongs to the Palacio
                district, a neighborhood full of history and social life.
                Surrounded by points of cultural interest, stores and
                restaurants. Close to the Vistillas Park and the Royal Palace
                Gardens. A perfect location for both residential and tourist
                use.
              </p>
            </Col>
          </Row>
        </Container>
      </LayoutEn>
    </>
  )
}

export default InfoEn
